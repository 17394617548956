document.addEventListener("DOMContentLoaded", function () {
  menuApp();
});

function menuApp() {
  const nav = document.querySelector("#navegacion");
  const abrir = document.querySelector("#abrir");
  const cerrar = document.querySelector("#cerrar");

  abrir.addEventListener("click", () => {
    nav.classList.add("visible");
    abrir.style.display = "none";
  });

  cerrar.addEventListener("click", () => {
    nav.classList.remove("visible");
    abrir.style.display = "block";
  });

  // // Agregar un evento de escucha de clic al documento
  // document.addEventListener("click", (event) => {
  //   // Verificar si el clic se realizó fuera del menú
  //   if (!nav.contains(event.target) && !abrir.contains(event.target)) {
  //     nav.classList.remove("visible");
  //     abrir.style.display = "block";
  //   }
  // });
}
